.main-app {
  background-color: #000000; 
  background-size: "100%";
  height: 100%;
  width: 100%;

  text-align: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  


}




.font-link {
    font-family: 'Hanalei Fill', cursive;
  }
  
.cta-button {
    padding: 15px;
    border: none;
    border-radius: 12px;
    min-width: 250px;
    color: white;
    font-size: 18px;
    cursor: pointer;
}
.center {
    margin-left: auto;
    margin-right: auto;
    font-family: "VT323";
}

.text-center {
    text-align: center;
    font-size: 35px;
    font-family: "VT323";
    color: white;
}
.text-center-small {
    text-align: center;
    font-size: 20px;
    font-family: "VT323";
    color: white;
}

.text-center-big {
    text-align: center;
    font-size: 50px;
    font-family: "VT323";
    color: white;
}
.text-center-blue {
    text-align: center;
    font-size: 35px;
    font-family: "VT323";
    color: #3168d8;
}
.contract-button {
    background-image: linear-gradient(to right, #3168d8 0%, #3168d8 51%, #3168d8 100%);
    flex: 1 1 auto;
    margin: 0px;
    padding: 0px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 10px #3168d8;
    border-radius: 0px;
    font-size: 35px;
    position:relative;
    width: 250px;
    transform: translateY(4px);
    
}

.contract-button:hover {
    background-position: right center; /* change the direction of the change here */
    
}
.etherscan-button:hover {
    background-position: right center; /* change the direction of the change here */
    box-shadow: 0 15px 17px 0 #3168d8, 0 17px 50px 0 #3168d8;
    
}

.button:active {
    background-color: #3168d8;
    box-shadow: 0 5px #3168d8;
    transform: translateY(4px);
    background-image: none;
}
.opensea-button:hover {
    background-position: right center; /* change the direction of the change here */
}
.fetch-etherscan-button {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
  border: 0;
  border-radius: 1px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  width: 300px;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 25px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  padding-top:5px;
  padding-bottom:5px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 25px;

    
}







.connect-wallet-button {
  border: 6px solid #000;
  border-radius: 10px;
  padding: 20px 15px;
  text-decoration: none;
  color: #000;
  text-transform: lowercase;
  font-size: 28px;
  width: 100px;
  line-height: 1.3em;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
  transition: all 0.3s ease 0s;
  font-family: "VT323";

    
}

.connect-wallet-button:focus {
    box-shadow: rgb(0, 255, 47) 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.connect-wallet-button:hover {
  border-color: rgb(0, 255, 47);
  color: rgb(0, 255, 47);;
}

.connect-wallet-button:active {
    box-shadow: rgb(0, 255, 47) 0 3px 7px inset;
    transform: translateY(2px);
  }



.mint-nft-button {
  border: 6px solid #000;
  border-radius: 10px;
  padding: 15px 10px;
  text-decoration: none;
  color: #000;
  text-transform: lowercase;
  font-size: 28px;
  width: 220px;
  line-height: 1.3em;
  font-weight: 600;
  letter-spacing: 2px;
  text-align: center;
  transition: all 0.3s ease 0s;
  font-family: 'Mouse Memoirs', sans-serif;

    
}

.mint-nft-button:focus {
    box-shadow: rgb(0, 255, 47) 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.mint-nft-button:hover {
  border-color: rgb(0, 255, 47);
  color: rgb(0, 255, 47);
}

.mint-nft-button:active {
    box-shadow: rgb(0, 255, 47) 0 3px 7px inset;
    transform: translateY(2px);
  }

.page-header-container {
    display: justify;
    align-items: center;
    margin: 20px 0;
    overflow: hidden;
}



a:link {
    color: rgb(0, 255, 47);
    font-family: 'Mouse Memoirs', sans-serif;
    
}

a:hover {
    color: rgb(98, 255, 0)
   
}

a:visited {
    color: rgb(0, 107, 0);
}
.center {
  margin-left: auto;
  margin-right: auto;
}


table {
    background: #232d37;
    border-radius: 0.25em;
    border-collapse: collapse;
    margin: 1em;
    font-size: 25px;
    width: 80%;
    border: 1px solid;
    align-content: center;
    margin-left: auto;
    margin-right: auto;

    
    
  }
  th {
    border-bottom: 1px solid #364043;
    color: #E2B842;
    font-size: 18px;
    font-weight: 600;
    padding: 0.5em 1em;
    text-align: left;
    border: 1px solid;
  }
  td {
    color: #fff;
    font-weight: 400;
    padding: 0.65em 1em;
    font-size: 18px;
    border: 1px solid;
  }
  tr:nth-child(odd) {
    background-color: #2b3844;
    border: 1px solid;
}
  .disabled td {
    color: #4F5F64;
  }
  tbody tr {
    transition: background 0.25s ease;
  }
  tbody tr:hover {
    background: #014055;
  }

  *, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
li {
    list-style-type: square;
    color: #3168d8;
    font-weight: 250;
    font-family: "VT323";
    font-size: 25px;
    text-align: center;
    
    

  }


.text { 
    font-family: 'Enriqueta', arial, serif; 
    line-height: 2.5; 
    margin: 0 0 0px; 
    margin-bottom: 0px;
    font-size: 18px; 
    font-weight: bold;
}

.description {  
    font-family: "Helvetica Neue",Arial,sans-serif; 
    font-size: 16px; 
    font-weight: 300; 
    line-height: 2.5; 
    margin-bottom: 0px; 
    margin-left: 10px;
}
.row {
    margin-left: -5px;
    margin-right: -5px;
    padding: 10px;
}

.row::after {
    content:"";
    clear: both;
    display: table;
}

.column15 {
    float: left;
    width: 15%;
    padding: 5px;
}
.column30 {
    float: left;
    width: 30%;
    padding: 5px;
}
.column20 {
    float: left;
    width: 20%;
    padding: 5px;
}
.column40 {
    float: left;
    width: 40%;
    padding: 5px;
}
.column5 {
    float: left;
    width: 5%;
    padding: 5px;
}



.input {
    width: 500px;
    margin-left: 5px;
}

.headerLogo {
    background-color: transparent;
}

h1 {
    color: rgb(0, 255, 47);
    font-weight: 250;
    text-align: center;
    letter-spacing: 2px;
    font-size: 69px;
    font-family: 'Mouse Memoirs', sans-serif;
    line-height: 70px
    
    
}

h2 {
    color: rgba(255, 255, 255, 0.85);
    font-weight: 250;
    text-align: center;
    letter-spacing: 2px;
    font-size: 35px;
    font-family: 'Mouse Memoirs', sans-serif;
    line-height: 37px
}


h3, h5 {
    color: rgba(255, 255, 255, 0.85);
    font-weight: 250;
    text-align: center;
    font-family: 'Mouse Memoirs', sans-serif;
    
}


h4 {
    margin-top: 0px;
    font-size: 35px;
    margin-bottom: 0.5em;
    color: rgba(255, 0, 0, 0.85);
    font-weight: 250;
    text-align: center;
    font-family: 'Mouse Memoirs', sans-serif;
    letter-spacing: 2px;
    line-height: 45px
}

h6 {
    margin-top: 0px;
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 250;
    font-size: 15px;
    text-align: left;
    font-family: 'Mouse Memoirs', sans-serif;
    text-align: center;
}

.table-pass {
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}

/* Style the navbar */
  #navbar {
    overflow: hidden;
    background-color: #333;
  }
  
  /* Navbar links */
  #navbar a {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 25px;
    text-decoration: none;
  }
  
  /* Page content */
  .content {
    padding: 16px;
  }
  
  /* The sticky class is added to the navbar with JS when it reaches its scroll position */
  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }
  
  /* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
  .sticky + .content {
    padding-top: 60px;
  }